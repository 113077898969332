<template>
  <div class="language-selector">
    <v-menu offset-y left content-class="language-selector__menu" max-height="350" v-if="locales.length > 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-img :src="require(`@/assets/images/flags/${currentLocale}.svg`)" max-width="24" />
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(locale, index) in computedLocales"
          :key="index"
          link
          :class="{ 'tw-bg-gray-100': currentLocale === locale.code }"
          @click="updateCurrentLocale(locale.code)"
        >
          <v-list-item-icon :class="[$vuetify.rtl ? 'tw-ml-4' : 'tw-mr-4']">
            <v-img :src="require(`@/assets/images/flags/${locale.code}.svg`)" max-width="24" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="{ 'tw-font-bold': currentLocale === locale.code }">
              {{ locale.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { allLocales } from '@/config/locales.config'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'LanguageSelector',
  created() {
    const browserLocale = navigator.language.toLowerCase()
    this.setCurrentLocale(browserLocale)
  },
  computed: {
    ...mapState({
      locales: state => state.global.config.locales,
      defaultLocale: state => state.global.config.defaultLocale,
      currentLocale: state => state.global.currentLocale,
    }),
    computedLocales() {
      return this.locales.map(locale => allLocales.find(l => l.code === locale)).filter(locale => locale !== undefined)
    },
  },
  methods: {
    ...mapActions({
      setCurrentLocale: 'global/setCurrentLocale',
      updateCurrentLocale: 'global/updateCurrentLocale',
    }),
  },
}
</script>

<style lang="scss" scoped>
.language-selector {
  &__menu {
    background-color: $white;
  }
}
</style>
