<template :key="$route.fullPath">
  <v-app v-if="loading">
    <SplashScreen />
  </v-app>
  <v-app v-else>
    <TopBar :config="config" v-if="$route.name !== 'Error' && !pageError" />
    <v-main class="tw-h-full">
      <Error v-if="pageError" />
      <router-view v-else :theme="theme" @notification="notificationError = true" />
    </v-main>
    <v-snackbar :timeout="5000" v-model="notificationError" centered top color="error">
      <span v-html="$t('notification.error')"></span>
    </v-snackbar>
    <DataConsent :theme="theme" v-if="!pageError" />
    <Theme :theme="theme" v-if="!pageError" />
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { sleep, uniq } from '@/utils/object.util'
import SplashScreen from '@/components/SplashScreen.vue'
import TopBar from '@/components/TopBar.vue'
import Theme from '@/components/Theme.vue'
import DataConsent from '@/components/DataConsent.vue'
import Error from '@/views/Error.vue'

export default {
  name: 'Main',
  components: {
    SplashScreen,
    TopBar,
    Theme,
    DataConsent,
    Error,
  },
  data() {
    return {
      loading: true,
      notificationError: false,
      pageError: false,
    }
  },
  async created() {
    let code = this.$route.params.code
    try {
      if (this.$route.name === 'Landing') {
        await this.getLanding(code)
        await this.incrementStat('hits')
        if (this.landing.status === 'redirection') {
          window.location.replace(this.landing.redirectUrl)
          await sleep(30000)
        }
      }
      await Promise.all([this.getConfig(), this.getTheme(), this.getUserInfo()])
      this.loadFont()
      this.setVuetifyVariables()
      this.generateFavicon()
      if (this.$route.name === 'Static') {
        await this.getStatic()
      }
      this.loading = false
    } catch {
      this.loading = false
      this.pageError = true
    }
  },
  watch: {
    currentLocale() {
      if (this.currentLocale === 'ar-sa') {
        this.$vuetify.rtl = true
      } else {
        this.$vuetify.rtl = false
      }
    },
  },
  computed: {
    ...mapState({
      config: state => state.global.config,
      theme: state => state.global.theme,
      currentLocale: state => state.global.currentLocale,
      landing: state => state.global.landing,
    }),
  },
  methods: {
    ...mapActions({
      getConfig: 'global/getConfig',
      getLanding: 'global/getLanding',
      getTheme: 'global/getTheme',
      getResult: 'global/getResult',
      getStatic: 'global/getStatic',
      getUserInfo: 'global/getUserInfo',
      incrementStat: 'global/incrementStat',
    }),
    loadFont() {
      const allFonts = [
        this.theme.global.font,
        this.theme.title.primary.font,
        this.theme.title.secondary.font,
        this.theme.title.tertiary.font,
      ]
      const uniqFonts = allFonts.filter(uniq)

      uniqFonts.forEach(font => {
        let link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = `https://fonts.googleapis.com/css2?family=${font}:wght@400;500;600;700`
        document.head.appendChild(link)
      })
    },
    setVuetifyVariables() {
      this.$vuetify.theme.themes.light = {
        ...this.$vuetify.theme.themes.light,
        primary: this.theme.cta.primary.background,
        secondary: this.theme.cta.secondary.color,
        tertiary: this.theme.cta.tertiary.color,
        ['input-color']: this.theme.input.active.background,
        ['input-background']: this.theme.input.default.background,
        stepperColor: this.theme.game.stepper.progress,
        stepperBackground: this.theme.game.stepper.background,
        cookie: this.theme.cookie.background,
      }
    },
    generateFavicon() {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      if (this.config.favicon) {
        link.href = this.config.favicon
      }
    },
  },
}
</script>
