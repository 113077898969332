export const allLocales = [
  {
    code: 'ar-sa',
    text: 'عرب',
  },
  {
    code: 'de-de',
    text: 'Deutsch',
  },
  {
    code: 'en-gb',
    text: 'English',
  },
  {
    code: 'en-us',
    text: 'English',
  },
  {
    code: 'es-es',
    text: 'Español',
  },
  {
    code: 'fr-fr',
    text: 'Français',
  },
  {
    code: 'it-it',
    text: 'Italiano',
  },
  {
    code: 'ja-jp',
    text: '日本',
  },
  {
    code: 'ko-kr',
    text: '한국인',
  },
  {
    code: 'nl-nl',
    text: 'Nederlands',
  },
  {
    code: 'pl-pl',
    text: 'Polskie',
  },
  {
    code: 'tr-tr',
    text: 'Türk',
  },
  {
    code: 'zh-cn',
    text: '中国人',
  },
]
