<template>
  <component :is="'style'">
    :root {
    {{ customPropertiesString }}
    }
  </component>
</template>

<script>
export default {
  name: 'Theme',
  props: {
    theme: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      customPropertiesString: '',
      customProperties: [],
    }
  },
  mounted() {
    if (typeof this.theme !== 'undefined') {
      this.customProperties = this.getCustomPropsFromObject(this.theme)
      this.customProperties.forEach(cp => {
        this.customPropertiesString += `${cp.label}: ${cp.value};`
      })
    }
  },
  methods: {
    getCustomPropsFromObject(object, prefix = '--theme-') {
      let results = []

      const keys = Object.keys(object)
      const keysToObject = keys.map(k => ({
        label: `${prefix}${k}`,
        value: object[k],
      }))

      keysToObject.forEach(subObject => {
        if (typeof subObject.value === 'object' && typeof subObject.value !== 'boolean') {
          results = results.concat(this.getCustomPropsFromObject(subObject.value, `${subObject.label}-`))
        } else if (typeof subObject.value !== 'boolean') {
          results = results.concat(subObject)
        }
      })

      return results
    },
  },
}
</script>
