import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=8b51d65c&scoped=true&"
import script from "./LanguageSelector.vue?vue&type=script&lang=js&"
export * from "./LanguageSelector.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSelector.vue?vue&type=style&index=0&id=8b51d65c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b51d65c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VImg,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMenu})
