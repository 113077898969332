<template>
  <v-overlay :value="isVisible">
    <v-snackbar
      :value="isVisible"
      vertical
      :timeout="-1"
      :light="!isDark"
      content-class="data-consent"
      color="cookie"
      centered
    >
      {{ $t('cookies.description') }}

      <template v-slot:action="{ attrs }">
        <v-btn text depressed :class="[$vuetify.rtl ? 'tw-ml-2' : 'tw-mr-2']" v-bind="attrs" @click="onClick(false)">
          {{ $t('cookies.decline') }}
        </v-btn>
        <v-btn
          color="primary"
          :class="[$vuetify.rtl ? 'tw-mr-2' : 'tw-ml-2']"
          depressed
          v-bind="attrs"
          @click="onClick(true)"
        >
          {{ $t('cookies.accept') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'DataConsent',
  props: {
    theme: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      cookieConsent: state => state.global.cookieConsent,
    }),
    isVisible() {
      return this.cookieConsent === null
    },
    isDark() {
      return this.theme['cookie'].dark
    },
  },
  methods: {
    ...mapActions({
      setCookieConsent: 'global/setCookieConsent',
    }),
    onClick(consent) {
      localStorage.setItem('gi-cookie-consent', consent)
      this.setCookieConsent(consent)
    },
  },
}
</script>

<style lang="scss">
.data-consent {
  color: var(--theme-cookie-color);
}
</style>
