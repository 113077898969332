<template>
  <v-app-bar app height="56" class="tw-shadow">
    <v-container class="tw-flex tw-items-center tw-px-0 tw-py-1 sm:tw-px-2 md:tw-px-6" style="height: 56px">
      <a :href="config.logo.href" target="_blank">
        <v-img
          :alt="`${config.client} Logo`"
          :class="[$vuetify.rtl ? 'tw-ml-4' : 'tw-mr-4']"
          contain
          :src="config.logo.src"
          height="28"
          :position="$vuetify.rtl ? 'center right' : 'center left'"
          max-width="200"
        />
      </a>

      <v-spacer></v-spacer>

      <LanguageSelector :class="[$vuetify.rtl ? 'tw--ml-3' : 'tw--mr-3']" />
    </v-container>
  </v-app-bar>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  name: 'TopBar',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  components: {
    LanguageSelector,
  },
}
</script>
